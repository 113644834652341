// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React from "react";
import { Container, Col, Row } from "reactstrap";
import { Link as RoutingNavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

import PageErrorBoundary from "global/errors/PageErrorBoundary";

// -------------------------------------------------------------------------------------------------
// Site descriptions and links to legal information and social media.
// http://company.wizards.com/fancontentpolicy
// https://www.termsfeed.com/blog/sample-copyright-notices/
// REVIEW
export default function AboutPage() {
	const rootDomain = `${window.location.protocol}//${window.location.hostname}`;
	const title = "About Us | Magic the Gathering | MTG Top Decks";
	const shortTitle = "About Us";
	const description = "Premier Magic: The Gathering content and analysis, for fans, by fans";

	const breadcrumbsLdJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement:": [{
			"@type": "ListItem",
			"position": 1,
			"name": "Home",
			"item": rootDomain
		},
		{
			"@type": "ListItem",
			"position": 2,
			"name": "About Us"
		}]
	};

	const pageLdJson = {
		"@context": "https://schema.org",
		"@type": "Organization",
		"url": rootDomain,
		"logo": rootDomain + "/assets/logo.png"
	};

	return (
		<PageErrorBoundary>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={shortTitle} />
				<meta property="og:description" content={description} />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:site_name" content="MTG Top Decks" />
				<script type="application/ld+json">{JSON.stringify(breadcrumbsLdJson)}</script>
				<script type="application/ld+json">{JSON.stringify(pageLdJson)}</script>
			</Helmet>

			<Container className="text-center">
				<Row>
					<Col>
						<h4>
							{/* TODO: Move copyright dates to central location */}
							Copyright <span>&#169;</span> 2025 Shrewd Apps. All rights reserved.
						</h4>
						<hr />
					</Col>
				</Row>

				<Row>
					<Col>
						<p className="lead">MTG Top Decks creates premier Magic: The Gathering content and analysis, for fans, by fans.</p>
						<p>Send us your questions or feedback here:</p>

						{/* FUTURE: Hookup social media links */}
						<a href="mailto:feedback@mtgtopdecks.com" className="m-1" alt="Email"><span className="display-4 fas fa-envelope-square"></span></a>
						{/*<a href="/socialMedia" className="m-1" target="_blank" alt="Twitter"><span className="display-4 fab fa-twitter-square"></span></a>*/}
						{/*<a href="/socialMedia" className="m-1" target="_blank" alt="Facebook"><span className="display-4 fab fa-facebook-square"></span></a>*/}
						{/*<a href="/socialMedia" className="m-1" target="_blank" alt="RSS"><span className="display-4 fas fa-rss-square"></span></a>*/}
					</Col>
				</Row>
				<br />

				<Row>
					<Col>
						<p>You can find all the legal stuff here:</p>
						<RoutingNavLink to="/terms-of-use">Terms of Use</RoutingNavLink>
						{" | "}
						<RoutingNavLink to="/privacy-policy">Privacy Policy</RoutingNavLink>
						{" | "}
						<RoutingNavLink to="/cookies">Cookie Policy</RoutingNavLink>
					</Col>
				</Row>
				<br />

				<Row className="text-center">
					<p><small>
						All original content on this page is © Shrewd Apps and may not be used or reproduced without consent.
						MTG Top Decks is unofficial Fan Content permitted under the Fan Content Policy. Not approved / endorsed by Wizards.
						Portions of the materials used are property of Wizards of the Coast. © Wizards of the Coast LLC.
					</small></p>
				</Row>
			</Container>
		</PageErrorBoundary>
	);
}