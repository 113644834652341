// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Table } from "reactstrap";
import { Button } from "reactstrap";
import { Badge } from "react-bootstrap";

import FormattedDate from "global/utilities/Date";
import { copyToClipboard } from "global/utilities/clipboardActions";
import LinkToCard from "common/links/LinkToCard";
import LinkToTournament from "common/links/LinkToTournament";
import CardRarityImagePopover from "common/CardRarityImagePopover";
import CardImagePopover from "common/CardImagePopover";
import ManaCost from "common/ManaCost";
import ColorIdentity from "common/ColorIdentity";

import { deckSchema, deckContentSchema, deckCardSchema } from "apiSchemas";
import { formatPrice, formatRank } from "global/utilities/format";
import { getLocalStorage } from "global/utilities/browserStorage";
import { getFormatDisplayName } from "common/resources/formatNames";
import LinkToMetagameArchetype from "../../common/links/LinkToMetagameArchetype";

// -------------------------------------------------------------------------------------------------
// REVIEW
export default function DeckPanel({ deck, displayOptions }) {
	return (
		<>
			{deck && <DeckSummary deck={deck} />}
			{deck && deck.cards && <DeckContentTable content={deck.cards} displayOptions={displayOptions} />}
		</>
	);
}

DeckPanel.propTypes = {
	deck: PropTypes.shape(deckSchema).isRequired,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function DeckSummary({ deck }) {
	const [isCopyingArenaDeck, setIsCopyingArenaDeck] = useState(false);
	const [isCopyingMtgoDeck, setIsCopyingMtgoDeck] = useState(false);
	const isDebugModeEnabled = getLocalStorage("isDebugModeEnabled") == "true";

	return (
		<>
			<ul className="list-unstyled">
				<li>
					<h2>{deck.playerName}</h2>
				</li>

				{deck && deck.cards &&
					<li>
						<strong>Archetype:</strong>
						{" "}
						{deck.archetypeVariationId ?
							<LinkToMetagameArchetype archetypeId={deck.archetypeId} archetypeVariationId={deck.archetypeVariationId}>
								{deck.archetypeVariationName}
							</LinkToMetagameArchetype> : "<Unknown>"}
						{" "}
						<ColorIdentity identity={deck.colorIdentity} />
					</li>
				}

				<li>
					<strong>Format:</strong>
					{" "}
					{getFormatDisplayName(deck.formatId)}
					{isDebugModeEnabled && deck.formatPeriodId &&
						<Badge className="m-1" bg="warning" text="light">
							{deck.formatPeriodId}
						</Badge>
					}
				</li>

				<li>
					<strong>Date:</strong>
					{" "}
					<FormattedDate value={deck.date} />
				</li>

				<li>
					<strong>Finish:</strong>
					{" "}
					{formatRank(deck.finish)}
					{deck.matchRecord && " (" + deck.matchRecord + ")"}
				</li>

				{deck && deck.cards &&
					<li>
						<strong>Price:</strong>
						{" "}
						{(deck.price) ? formatPrice(deck.price) : "-"}
					</li>
				}

				<li>
					<strong>Tournament:</strong>
					{" "}
					<LinkToTournament tournamentId={deck.tournamentId}>
						{deck.tournamentName ? deck.tournamentName : "-"}
					</LinkToTournament>
				</li>

				<li>
					<strong>Organizer:</strong>
					{" "}
					{deck.tournamentOrganizerName}
				</li>

				<li>
					<strong>Source:</strong>
					{" "}
					<a href={deck.sourceSiteUrl} target="_blank" rel="nofollow noopener noreferrer">
						{deck.sourceSiteName ? deck.sourceSiteName : "<unknown>"}
						{" "}
						<span className="fas fa-external-link-alt" />
					</a>
				</li>

				{deck && deck.cards &&
					<li>
						{isCopyingArenaDeck ?
							<Button style={{ width: 150 }} className="mt-3" outline color="primary">
								<span className="fas fa-clipboard-check" />
								{" "}
								Copied!
							</Button> :
							<Button style={{ width: 150 }} className="mt-3" outline color="primary" onClick={() => { copyToClipboard(convertDeckToTextArena(deck)); setIsCopyingArenaDeck(true); setTimeout(() => setIsCopyingArenaDeck(false), 3000); }}>
								<span className="far fa-clipboard" />
								{" "}
								MTG Arena
							</Button>
						}

						{isCopyingMtgoDeck ?
							<Button style={{ width: 150 }} className="mt-3 ms-2" outline color="primary">
								<span className="fas fa-clipboard-check" />
								{" "}
								Copied!
							</Button> :
							<Button style={{ width: 150 }} className="mt-3 ms-2" outline color="primary" onClick={() => { copyToClipboard(convertDeckToTextMtgo(deck)); setIsCopyingMtgoDeck(true); setTimeout(() => setIsCopyingMtgoDeck(false), 3000); }}>
								<span className="far fa-clipboard" />
								{" "}
								MTGO
							</Button>
						}
					</li>
				}
			</ul>
		</>
	);
}

DeckSummary.propTypes = {
	deck: PropTypes.shape(deckSchema)
};

// -------------------------------------------------------------------------------------------------
function DeckContentTable({ content }) {
	return (
		<Table borderless size="sm">
			<tbody>
				{getCards(content.commanders, "Commanders")}
				{getCards(content.companions, "Companions")}
				{getSpecificCards(content.mainboard, "Creatures", 2, 0)}
				{getSpecificCards(content.mainboard, "Planeswalkers", 64, 0)}
				{getSpecificCards(content.mainboard, "Spells", 4 + 8 + 16 + 32 + 32768, 64 + 2 + 1)}
				{getSpecificCards(content.mainboard, "Lands", 1, 64 + 2)}
				{getCards(content.sideboard, "Sideboard")}
				{getCards(content.maybeboard, "Maybeboard")}
			</tbody>
		</Table>
	);
}

DeckContentTable.propTypes = {
	content: PropTypes.shape(deckContentSchema),
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function DeckCardTableCategoryRow({ cardTypesName, cardCount }) {
	return (
		<tr>
			<td colSpan={4}><strong>{cardTypesName}{cardCount ? " (" + cardCount + ")" : ""}</strong></td>
		</tr>
	);
}

DeckCardTableCategoryRow.propTypes = {
	cardTypesName: PropTypes.string.isRequired,
	cardCount: PropTypes.number.isRequired
};

// -------------------------------------------------------------------------------------------------
// TODO: Make sure all elements are the same height (text, set rarity image, mana symbols)
function DeckCardTableCardRow({ card, deckLocation }) {
	return (
		<tr style={{ lineHeight: 1 }}>
			<td className="text-end">{card.count}</td>

			<td className="d-none d-md-table-cell">
				<LinkToCard cardId={card.cardId} multiface={card.multiface} deckLocation={deckLocation}>
					{card.cardName}
				</LinkToCard>
				{" "}
				<CardRarityImagePopover cardId={card.cardId} multiface={card.multiface} cardVariation={card.cardVariation} rarity={card.rarity} size="Small" style={{ maxHeight: 16, maxWidth: 24 }} deckLocation={deckLocation} />
				{card.isReserveList && <>{" "}<Badge pill bg="secondary" text="dark">reserved list</Badge></>}
			</td>

			<td className="d-table-cell d-md-none">
				<CardImagePopover cardId={card.cardId} deckLocation={deckLocation} />
				{" "}
				<LinkToCard cardId={card.cardId} multiface={card.multiface} deckLocation={deckLocation}>
					{card.cardName}
				</LinkToCard>
				{card.isReserveList && <>{" "}<Badge pill bg="secondary" text="dark">RL</Badge></> }
			</td>

			<td>
				<ManaCost cost={card.manaCost} />
			</td>

			<td className="text-end">{(card.price) ? formatPrice(card.price) : "-"}</td>
		</tr>
	);
}

DeckCardTableCardRow.propTypes = {
	card: PropTypes.shape(deckCardSchema).isRequired,
	deckLocation: PropTypes.string.isRequired
};

// -------------------------------------------------------------------------------------------------
function getSpecificCards(cards, cardTypesName, includeCardTypes, excludeCardTypes) {
	if (cards && cards.some(card => ((card.cardTypes & includeCardTypes) > 0) && (excludeCardTypes === 0 || (card.cardTypes & excludeCardTypes) === 0))) {
		const sortedCards = cards
			.filter(card => ((card.cardTypes & includeCardTypes) > 0) && (excludeCardTypes === 0 || (card.cardTypes & excludeCardTypes) === 0))
			.sort(mainboardCardSortComparer);

		const cardSectionHeader = <DeckCardTableCategoryRow
			key={cardTypesName}
			cardTypesName={cardTypesName}
			cardCount={getCardCount(sortedCards)} />;

		const cardRows = sortedCards.map(card =>
			<DeckCardTableCardRow
				key={cardTypesName + "." + card.cardId}
				card={card}
				deckLocation={cardTypesName} />);

		return [cardSectionHeader].concat(cardRows);
	} else {
		return null;
	}
}

// -------------------------------------------------------------------------------------------------
function getCards(cards, sectionName) {
	if (cards && cards.length > 0) {
		const sortedCards = cards.sort(mainboardCardSortComparer);

		const cardSectionHeader = <DeckCardTableCategoryRow
			key={sectionName}
			cardTypesName={sectionName}
			cardCount={getCardCount(sortedCards)} />;

		const cardRows = sortedCards.map(card =>
			<DeckCardTableCardRow
				key={sectionName + "." + card.cardId}
				card={card}
				deckLocation={sectionName} />);

		return [cardSectionHeader].concat(cardRows);
	} else {
		return null;
	}
}

// -------------------------------------------------------------------------------------------------
function mainboardCardSortComparer(card1, card2) {
	if (getCardTypeOrder(card1) !== getCardTypeOrder(card2)) {
		return getCardTypeOrder(card1) - getCardTypeOrder(card2);
	}

	if (card1.manaValue) {
		if (card2.manaValue) {
			if (card1.manaValue !== card2.manaValue) {
				return card1.manaValue - card2.manaValue;
			}

			return card1.cardName.localeCompare(card2.cardName);
		} else {
			return 1;
		}
	} else {
		if (card2.manaValue) {
			return -1;
		} else {
			return card1.cardName.localeCompare(card2.cardName);
		}
	}
}

function simpleCardSortComparer(card1, card2) {
	return card1.cardName.localeCompare(card2.cardName);
}

function getCardTypeOrder(card) {
	if ((card.cardTypes & 2) > 0) {
		// Creature
		return 1;
	} else if ((card.cardTypes & 1) > 0) {
		// Land
		return 4;
	} else if ((card.cardTypes & 64) > 0) {
		// Planeswalker
		return 2;
	} else if ((card.cardTypes & (4 + 8 + 16 + 32)) > 0) {
		// Spell
		return 3;
	} else {
		return 5;
	}
}

// -------------------------------------------------------------------------------------------------
function getCardCount(cards) {
	if (cards) {
		return cards.reduce((accumulator, card) => accumulator + card.count, 0);
	} else {
		return 0;
	}
}

// -------------------------------------------------------------------------------------------------
function convertDeckToTextArena(deck) {
	let output = "";

	if (deck.cards) {
		if (deck.cards.commanders) {
			output += "Commander\r\n";
			deck.cards.commanders.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.companions) {
			if (output.length > 0) {
				output += "\r\n";
			}

			output += "Companion\r\n";
			deck.cards.companions.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.mainboard) {
			if (output.length > 0) {
				output += "\r\n";
			}

			output += "Deck\r\n";
			deck.cards.mainboard.sort(mainboardCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.sideboard) {
			if (output.length > 0) {
				output += "\r\n";
			}

			output += "Sideboard\r\n";
			deck.cards.sideboard.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.maybeboard) {
			if (output.length > 0) {
				output += "\r\n";
			}

			deck.cards.maybeboard.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}
	}

	return output;
}

function convertDeckToTextMtgo(deck) {
	let output = "";

	if (deck.cards) {
		if (deck.cards.commanders) {
			deck.cards.commanders.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.companions) {
			deck.cards.companions.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.mainboard) {
			deck.cards.mainboard.sort(mainboardCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.sideboard) {
			if (output.length > 0) {
				output += "\r\n";
			}

			deck.cards.sideboard.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.maybeboard) {
			deck.cards.maybeboard.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}
	}

	return output;
}

function formatCard(card) {
	if (card.count) {
		return card.count + " " + card.cardName + "\r\n";
	} else if (card.minimumCount || card.maximumCount) {
		if (card.minimumCount === card.maximumCount) {
			return card.minimumCount + " " + card.cardName + "\r\n";
		} else {
			return card.minimumCount + "-" + card.maximumCount + " " + card.cardName + "\r\n";
		}
	} else {
		return null;
	}
}