// =================================================================================================
// © 2021 Shrewd Apps
// ALL RIGHTS RESERVED.

import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Table } from "reactstrap";
import { Button } from "reactstrap";
import { Badge } from "react-bootstrap";
import { ButtonGroup } from "reactstrap";

import Price from "global/utilities/Price";
import LinkToDeck from "common/links/LinkToDeck";
import TableCellItem from "common/TableCellItem";
import FormattedDate from "global/utilities/Date";
import { formatInteger, formatIntegerRange, formatPercentage } from "global/utilities/format";
import { copyToClipboard } from "global/utilities/clipboardActions";
import UncontrolledTabs from "global/utilities/UncontrolledTabs";
import LinkToCard from "common/links/LinkToCard";
import LinkToTournament from "common/links/LinkToTournament";
import CardRarityImagePopover from "common/CardRarityImagePopover";
import ManaCost from "common/ManaCost";
import ColorIdentity from "common/ColorIdentity";

import { getLocalStorage } from "global/utilities/browserStorage";
import { metagameArchetypeSchema, deckSchema, aggregateDeckSchema, deckContentSchema, aggregateDeckContentSchema, deckCardSchema, metagameArchetypeVariationSchema } from "apiSchemas";
import { formatPrice, formatRank } from "global/utilities/format";
import { getFormatDisplayName } from "common/resources/formatNames";

// -------------------------------------------------------------------------------------------------
// REVIEW
export default function MetagameArchetypePanel({ archetype }) {
	return (
		<>
			{archetype && <ArchetypeSummary archetype={archetype} />}
		</>
	);
}

MetagameArchetypePanel.propTypes = {
	archetype: PropTypes.shape(metagameArchetypeSchema).isRequired,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function ArchetypeSummary({ archetype }) {
	return (
		<>
			<ul className="list-unstyled">
				<li>
					<h2>
						{archetype.archetypeName ? archetype.archetypeName : archetype.archetypeId}
					</h2>
				</li>
			</ul>
			{archetype.variations.length > 0 ? <UncontrolledTabs tabs={constructTabs(archetype.variations)} /> : null}
		</>
	);
}

function constructTabs(variations) {
	return variations.map(variation => constructTab(variation));
}

function constructTab(variation) {
	return {
		id: variation.archetypeVariationId,
		header:
			<>
				{variation.archetypeVariationName ? variation.archetypeVariationName : variation.archetypeVariationId}
				{" "}
				<ColorIdentity identity={variation.colorIdentity} />
				{variation.numberOfDecks ?
					<Badge bg="secondary" text="dark">{formatInteger(variation.numberOfDecks)}</Badge> : null
				}
			</>,
		contents: <VariationPanel variation={variation} />
	};
}

ArchetypeSummary.propTypes = {
	archetype: PropTypes.shape(metagameArchetypeSchema)
};

// -------------------------------------------------------------------------------------------------
function VariationPanel({ variation }) {
	const [currentTab, setCurrentTab] = useState("representative");

	return (
		<>
			<div className="text-center">
				<ButtonGroup className="p-2" size="sm">
					<Button style={{ cursor: "pointer" }} onClick={() => setCurrentTab("representative")} active={currentTab === "representative"}>Representative</Button>
					{variation.decks.length > 1 ?
						<>
							<Button style={{ cursor: "pointer" }} onClick={() => setCurrentTab("average")} active={currentTab === "average"} disabled={!variation.averageDeck}>Average</Button>
							<Button style={{ cursor: "pointer" }} onClick={() => setCurrentTab("aggregate")} active={currentTab === "aggregate"} disabled={!variation.aggregateDeck}>Aggregate</Button>
							<Button style={{ cursor: "pointer" }} onClick={() => setCurrentTab("all")} active={currentTab === "all"} disabled={!variation.decks && variation.decks.length > 0}>All</Button>
						</> : null
					}
				</ButtonGroup>
			</div>

			{currentTab === "representative" && <DeckPanel deck={variation.representativeDeck} />}
			{currentTab === "average" && <DeckPanel deck={variation.averageDeck} />}
			{currentTab === "aggregate" && <AggregateDeckPanel deck={variation.aggregateDeck} />}
			{currentTab === "all" && <DecksTable decks={variation.decks} />}
		</>
	);
}

VariationPanel.propTypes = {
	variation: PropTypes.shape(metagameArchetypeVariationSchema)
};

// -------------------------------------------------------------------------------------------------
function DeckPanel({ deck }) {
	const [isCopyingArenaDeck, setIsCopyingArenaDeck] = useState(false);
	const [isCopyingMtgoDeck, setIsCopyingMtgoDeck] = useState(false);
	const isDebugModeEnabled = getLocalStorage("isDebugModeEnabled") == "true";

	return (
		<>
			<ul className="list-unstyled">
				{deck.playerName ?
					<li>
						<strong>Player:</strong>
						{" "}
						{deck.playerName ? deck.playerName : ""}

					</li> : null
				}

				<li>
					<strong>Format:</strong>
					{" "}
					{getFormatDisplayName(deck.formatId)}
				</li>

				{deck.date ?
					<li>
						<strong>Date:</strong>
						{" "}
						{deck.date ? <FormattedDate value={deck.date} /> : null}
					</li> : null
				}

				{deck.finish || deck.overallRecord ?
					<li>
						<strong>Finish:</strong>
						{" "}
						{formatRank(deck.finish)}
						{deck.overallRecord && " (" + deck.overallRecord + ")"}
					</li> : null
				}

				<li>
					<strong>Price:</strong>
					{" "}
					{(deck.price) ? formatPrice(deck.price) : "-"}
				</li>

				{deck.tournamentId ?
					<li>
						<strong>Tournament:</strong>
						{" "}
						<LinkToTournament tournamentId={deck.tournamentId}>
							{deck.tournamentName ? deck.tournamentName : "-"}
						</LinkToTournament>
					</li> : null
				}

				{deck.organizer ?
					<li>
						<strong>Organizer:</strong>
						{" "}
						{deck.tournamentOrganizerName}
					</li> : null
				}

				{deck.overlapVersusArchetypeAverage ?
					<li>
						<strong>Overlap with Average:</strong>
						{" "}
						{formatPercentage(deck.overlapVersusArchetypeAverage)}
					</li> : null
				}

				{isDebugModeEnabled && deck.recentPerformance &&
					<li>
						<strong>Recent Performance:</strong>
						{" "}
						{deck.recentRecord}
						{" ("}
						{formatPercentage(deck.recentPerformance)}
						{" )"}
					</li>
				}

				<li>
					{isCopyingArenaDeck ?
						<Button style={{ width: 150 }} className="mt-3" outline color="primary">
							<span className="fas fa-clipboard-check" />
							{" "}
							Copied!
						</Button> :
						<Button style={{ width: 150 }} className="mt-3" outline color="primary" onClick={() => { copyToClipboard(convertDeckToTextArena(deck)); setIsCopyingArenaDeck(true); setTimeout(() => setIsCopyingArenaDeck(false), 3000); }}>
							<span className="far fa-clipboard" />
							{" "}
							MTG Arena
						</Button>
					}

					{isCopyingMtgoDeck ?
						<Button style={{ width: 150 }} className="mt-3 ms-2" outline color="primary">
							<span className="fas fa-clipboard-check" />
							{" "}
							Copied!
						</Button> :
						<Button style={{ width: 150 }} className="mt-3 ms-2" outline color="primary" onClick={() => { copyToClipboard(convertDeckToTextMtgo(deck)); setIsCopyingMtgoDeck(true); setTimeout(() => setIsCopyingMtgoDeck(false), 3000); }}>
							<span className="far fa-clipboard" />
							{" "}
							MTGO
						</Button>
					}
				</li>
			</ul>

			<DeckContentsTable content={deck.cards} />
		</>
	);
}

DeckPanel.propTypes = {
	deck: PropTypes.shape(deckSchema)
};

// -------------------------------------------------------------------------------------------------
function DeckContentsTable({ content }) {
	return (
		<>
			<Table borderless size="sm">
				<tbody>
					{getCards(content.commanders, "Commanders")}
					{getCards(content.companions, "Companions")}
					{getSpecificCards(content.mainboard, "Creatures", 2, 0)}
					{getSpecificCards(content.mainboard, "Planeswalkers", 64, 0)}
					{getSpecificCards(content.mainboard, "Spells", 4 + 8 + 16 + 32 + 32768, 64 + 2 + 1)}
					{getSpecificCards(content.mainboard, "Lands", 1, 64 + 2)}
					{getCards(content.sideboard, "Sideboard")}
					{getCards(content.maybeboard, "Maybeboard")}
				</tbody>
			</Table>
		</>
	);
}

DeckContentsTable.propTypes = {
	content: PropTypes.shape(deckContentSchema),
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function AggregateDeckPanel({ deck }) {
	const [isCopyingArenaDeck, setIsCopyingArenaDeck] = useState(false);
	const [isCopyingMtgoDeck, setIsCopyingMtgoDeck] = useState(false);

	return (
		<>
			<ul className="list-unstyled">
				<li>
					<strong>Format:</strong>
					{" "}
					{getFormatDisplayName(deck.formatId)}
				</li>

				<li>
					<strong>Price:</strong>
					{" "}
					{(deck.price) ? formatPrice(deck.price) : "-"}
				</li>

				<li>
					{isCopyingArenaDeck ?
						<Button style={{ width: 150 }} className="mt-3" outline color="primary">
							<span className="fas fa-clipboard-check" />
							{" "}
							Copied!
						</Button> :
						<Button style={{ width: 150 }} className="mt-3" outline color="primary" onClick={() => { copyToClipboard(convertDeckToTextArena(deck)); setIsCopyingArenaDeck(true); setTimeout(() => setIsCopyingArenaDeck(false), 3000); }}>
							<span className="far fa-clipboard" />
							{" "}
							MTG Arena
						</Button>
					}

					{isCopyingMtgoDeck ?
						<Button style={{ width: 150 }} className="mt-3 ms-2" outline color="primary">
							<span className="fas fa-clipboard-check" />
							{" "}
							Copied!
						</Button> :
						<Button style={{ width: 150 }} className="mt-3 ms-2" outline color="primary" onClick={() => { copyToClipboard(convertDeckToTextMtgo(deck)); setIsCopyingMtgoDeck(true); setTimeout(() => setIsCopyingMtgoDeck(false), 3000); }}>
							<span className="far fa-clipboard" />
							{" "}
							MTGO
						</Button>
					}
				</li>
			</ul>

			<AggregateDeckContentsTable content={deck.cards} />
		</>
	);
}

AggregateDeckPanel.propTypes = {
	deck: PropTypes.shape(aggregateDeckSchema)
};

// -------------------------------------------------------------------------------------------------
function AggregateDeckContentsTable({ content }) {
	return (
		<>
			<Table borderless size="sm">
				<tbody>
					{getCards(content.commanders, "Commanders")}
					{getCards(content.companions, "Companions")}
					{getSpecificCards(content.mainboard, "Creatures", 2, 0)}
					{getSpecificCards(content.mainboard, "Planeswalkers", 64, 0)}
					{getSpecificCards(content.mainboard, "Spells", 4 + 8 + 16 + 32, 64 + 2 + 1)}
					{getSpecificCards(content.mainboard, "Lands", 1, 64 + 2)}
					{getCards(content.sideboard, "Sideboard")}
					{getCards(content.maybeboard, "Maybeboard")}
				</tbody>
			</Table>
		</>
	);
}

AggregateDeckContentsTable.propTypes = {
	content: PropTypes.shape(aggregateDeckContentSchema),
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
function DeckCardTableCategoryRow({ cardTypesName, cardCount }) {
	return (
		<tr>
			<td colSpan={4}><strong>{cardTypesName}{cardCount ? " (" + cardCount + ")" : ""}</strong></td>
		</tr>
	);
}

DeckCardTableCategoryRow.propTypes = {
	cardTypesName: PropTypes.string.isRequired,
	cardCount: PropTypes.number.isRequired
};

// -------------------------------------------------------------------------------------------------
// TODO: Make sure all elements are the same height (text, set rarity image, mana symbols)
function DeckCardTableCardRow({ card, deckLocation }) {
	return (
		<tr style={{ lineHeight: 1 }}>
			{card.count ?
				<td className="text-end">{formatInteger(card.count)}</td> :
				<td className="text-end text-nowrap">{formatIntegerRange(card.minimumCount, card.maximumCount)}</td>
			}

			<td>
				<LinkToCard cardId={card.cardId} multiface={card.multiface} deckLocation={deckLocation}>
					{card.cardName}
				</LinkToCard>
				{" "}
				<CardRarityImagePopover cardId={card.cardId} multiface={card.multiface} cardVariation={card.cardVariation} rarity={card.rarity} size="Small" style={{ maxHeight: 16, maxWidth: 24 }} deckLocation={deckLocation} />
				{card.frequency ? <>{" ("}{formatPercentage(card.frequency)}{")"} </> : null}
				{card.isReserveList ?
					<>
						<Badge className="d-none d-md-inline" pill bg="secondary" text="dark">reserved list</Badge>
						<Badge className="d-inline d-md-none" pill bg="secondary" text="dark">RL</Badge>
					</> : null}
			</td>

			<td>
				<ManaCost cost={card.manaCost} />
			</td>

			<td className="text-end">{(card.price) ? formatPrice(card.price) : "-"}</td>
		</tr>
	);
}

DeckCardTableCardRow.propTypes = {
	card: PropTypes.shape(deckCardSchema).isRequired,
	deckLocation: PropTypes.string.isRequired
};

// -------------------------------------------------------------------------------------------------
function getSpecificCards(cards, cardTypesName, includeCardTypes, excludeCardTypes) {
	if (cards && cards.some(card => ((card.cardTypes & includeCardTypes) > 0) && (excludeCardTypes === 0 || (card.cardTypes & excludeCardTypes) === 0))) {
		const sortedCards = cards
			.filter(card => ((card.cardTypes & includeCardTypes) > 0) && (excludeCardTypes === 0 || (card.cardTypes & excludeCardTypes) === 0))
			.sort(mainboardCardSortComparer);

		const cardSectionHeader = <DeckCardTableCategoryRow
			key={cardTypesName}
			cardTypesName={cardTypesName}
			cardCount={getCardCount(sortedCards)} />;

		const cardRows = sortedCards.map(card =>
			<DeckCardTableCardRow
				key={cardTypesName + "." + card.cardId}
				card={card}
				deckLocation={cardTypesName} />);

		return [cardSectionHeader].concat(cardRows);
	} else {
		return null;
	}
}

// -------------------------------------------------------------------------------------------------
function getCards(cards, sectionName) {
	if (cards && cards.length > 0) {
		const sortedCards = cards.sort(mainboardCardSortComparer);

		const cardSectionHeader = <DeckCardTableCategoryRow
			key={sectionName}
			cardTypesName={sectionName}
			cardCount={getCardCount(sortedCards)} />;

		const cardRows = sortedCards.map(card =>
			<DeckCardTableCardRow
				key={sectionName + "." + card.cardId}
				card={card}
				deckLocation={sectionName} />);

		return [cardSectionHeader].concat(cardRows);
	} else {
		return null;
	}
}

// -------------------------------------------------------------------------------------------------
function mainboardCardSortComparer(card1, card2) {
	if (getCardTypeOrder(card1) !== getCardTypeOrder(card2)) {
		return getCardTypeOrder(card1) - getCardTypeOrder(card2);
	}

	if (card1.manaValue) {
		if (card2.manaValue) {
			if (card1.manaValue !== card2.manaValue) {
				return card1.manaValue - card2.manaValue;
			}

			return card1.cardName.localeCompare(card2.cardName);
		} else {
			return 1;
		}
	} else {
		if (card2.manaValue) {
			return -1;
		} else {
			return card1.cardName.localeCompare(card2.cardName);
		}
	}
}

function simpleCardSortComparer(card1, card2) {
	return card1.cardName.localeCompare(card2.cardName);
}

function getCardTypeOrder(card) {
	if ((card.cardTypes & 2) > 0) {
		// Creature
		return 1;
	} else if ((card.cardTypes & 1) > 0) {
		// Land
		return 4;
	} else if ((card.cardTypes & 64) > 0) {
		// Planeswalker
		return 2;
	} else if ((card.cardTypes & (4 + 8 + 16 + 32)) > 0) {
		// Spell
		return 3;
	} else {
		return 5;
	}
}

// -------------------------------------------------------------------------------------------------
function getCardCount(cards) {
	if (cards) {
		return cards.reduce((accumulator, card) => accumulator + (card.count ? card.count : 0), 0);
	} else {
		return 0;
	}
}

// -------------------------------------------------------------------------------------------------
//function convertDeckToTextArena(deck) {
//	let output = "";

//	if (deck.cards) {
//		if (deck.cards.commanders) {
//			output += "Commander\r\n";
//			deck.cards.commanders.forEach(card => { output += formatCard(card); });
//		}

//		if (deck.cards.companions) {
//			if (output.length > 0) {
//				output += "\r\n";
//			}

//			output += "Companion\r\n";
//			deck.cards.companions.forEach(card => { output += formatCard(card); });
//		}

//		if (deck.cards.mainboard) {
//			if (output.length > 0) {
//				output += "\r\n";
//			}

//			output += "Deck\r\n";
//			deck.cards.mainboard.forEach(card => { output += formatCard(card); });
//		}

//		if (deck.cards.sideboard) {
//			if (output.length > 0) {
//				output += "\r\n";
//			}

//			output += "Sideboard\r\n";
//			deck.cards.sideboard.forEach(card => { output += formatCard(card); });
//		}

//		if (deck.cards.maybeboard) {
//			if (output.length > 0) {
//				output += "\r\n";
//			}

//			deck.cards.maybeboard.forEach(card => { output += formatCard(card); });
//		}
//	}

//	return output;
//}

//function convertDeckToTextMtgo(deck) {
//	let output = "";

//	if (deck.cards) {
//		if (deck.cards.commanders) {
//			deck.cards.commanders.forEach(card => { output += formatCard(card); });
//		}

//		if (deck.cards.companions) {
//			deck.cards.companions.forEach(card => { output += formatCard(card); });
//		}

//		if (deck.cards.mainboard) {
//			deck.cards.mainboard.forEach(card => { output += formatCard(card); });
//		}

//		if (deck.cards.sideboard) {
//			if (output.length > 0) {
//				output += "\r\n";
//			}

//			deck.cards.sideboard.forEach(card => { output += formatCard(card); });
//		}

//		if (deck.cards.maybeboard) {
//			deck.cards.maybeboard.forEach(card => { output += formatCard(card); });
//		}
//	}

//	return output;
//}

//function formatCard(card) {
//	if (card.count) {
//		return card.count + " " + card.name + "\r\n";
//	} else if (card.minimumCount || card.maximumCount) {
//		if (card.minimumCount === card.maximumCount) {
//			return card.minimumCount + " " + card.name + "\r\n";
//		} else {
//			return card.minimumCount + "-" + card.maximumCount + " " + card.name + "\r\n";
//		}
//	} else {
//		return null;
//	}
//}

// -------------------------------------------------------------------------------------------------
// Table that shows list of linked tournament summaries.
// REVIEW
function DecksTable({ decks, displayOptions }) {
	const tableNotStriped = displayOptions && displayOptions.showTableNotStriped;
	const tableBordered = displayOptions && displayOptions.showTableBordered;

	return (
		<Table striped={!tableNotStriped} bordered={tableBordered} size="sm">
			<thead>
				<tr>
					<th>Player</th>
					<th>Date</th>
					<th>Finish</th>
					<th style={{ maxWidth: "75px" }} className="text-wrap text-end d-none d-sm-table-cell">Overlap with Average</th>
					<th className="text-end d-none d-lg-table-cell">Price</th>
				</tr>
			</thead>

			<tbody>
				{decks && decks.length > 0 && decks.map(deck =>
					<DecksTableRow
						key={deck.deckId}
						deck={deck} />
				)}
				{(!decks || decks.length === 0) &&
					<tr>
						<td className="text-muted text-center" colSpan={6}>
							No decks found
						</td>
					</tr>
				}
			</tbody>
		</Table>
	);
}

DecksTable.propTypes = {
	decks: PropTypes.arrayOf(PropTypes.shape(deckSchema)).isRequired,
	displayOptions: PropTypes.object
};

// -------------------------------------------------------------------------------------------------
// TODO: Consider reversing archetype / player name so player can link to deck and archetype name can link to archetype
function DecksTableRow({ deck }) {
	return (
		<tr>
			{/* TODO: Add player name filter component */}
			<td style={{ maxWidth: "120px" }}>
				<TableCellItem>
					<LinkToDeck deck={deck}>
						{deck.playerName}
					</LinkToDeck>
				</TableCellItem>
				{" "}
				{/* FUTURE: Currently doesn't show ellipse properly if color identity is inside TableCellItem */}
				<ColorIdentity identity={deck.colorIdentity} />
			</td>

			<td className="text-nowrap" style={{ maxWidth: "75px" }}>
				<TableCellItem>
					<FormattedDate value={deck.date} />
				</TableCellItem>
			</td>

			<td className="text-nowrap" style={{ maxWidth: "75px" }}>
				<TableCellItem>
					{formatRank(deck.finish)}
					{deck.matchRecord && " (" + deck.matchRecord + ")"}
				</TableCellItem>
			</td>

			<td className="text-nowrap text-end d-none d-sm-table-cell" style={{ maxWidth: "50px" }}>
				<TableCellItem>
					{formatPercentage(deck.overlapVersusArchetypeAverage)}
				</TableCellItem>
			</td>

			<td className="text-end text-nowrap d-none d-lg-table-cell" style={{ maxWidth: "50px" }}>
				<Price value={deck.price} />
			</td>
		</tr>
	);
}

DecksTableRow.propTypes = {
	deck: PropTypes.shape(deckSchema).isRequired
};

// -------------------------------------------------------------------------------------------------
function convertDeckToTextArena(deck) {
	let output = "";

	if (deck.cards) {
		if (deck.cards.commanders) {
			output += "Commander\r\n";
			deck.cards.commanders.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.companions) {
			if (output.length > 0) {
				output += "\r\n";
			}

			output += "Companion\r\n";
			deck.cards.companions.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.mainboard) {
			if (output.length > 0) {
				output += "\r\n";
			}

			output += "Deck\r\n";
			deck.cards.mainboard.sort(mainboardCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.sideboard) {
			if (output.length > 0) {
				output += "\r\n";
			}

			output += "Sideboard\r\n";
			deck.cards.sideboard.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.maybeboard) {
			if (output.length > 0) {
				output += "\r\n";
			}

			deck.cards.maybeboard.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}
	}

	return output;
}

function convertDeckToTextMtgo(deck) {
	let output = "";

	if (deck.cards) {
		if (deck.cards.commanders) {
			deck.cards.commanders.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.companions) {
			deck.cards.companions.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.mainboard) {
			deck.cards.mainboard.sort(mainboardCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.sideboard) {
			if (output.length > 0) {
				output += "\r\n";
			}

			deck.cards.sideboard.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}

		if (deck.cards.maybeboard) {
			deck.cards.maybeboard.sort(simpleCardSortComparer).
				forEach(card => { output += formatCard(card); });
		}
	}

	return output;
}

function formatCard(card) {
	if (card.count) {
		return card.count + " " + card.cardName + "\r\n";
	} else if (card.minimumCount || card.maximumCount) {
		return card.maximumCount + " " + card.cardName + "\r\n";
	} else {
		return null;
	}
}